.sidebar__header {
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    border-bottom: 1px dashed #393945;
    position: relative;
}

.sidebar__logo {
    display: grid;
    place-items: center;
    transition: color .2s ease;
}

.sidebar__toggle {
    width: 30px;
    height: 30px;
    background-color: var(--kt-app-sidebar-base-toggle-btn-bg-color);
    color: var(--kt-text-muted);
    right: 0;
    display: grid;
    place-items: center;
    border: 0;
    border-radius: 5px;
    box-shadow: var(--kt-app-sidebar-base-toggle-btn-box-shadow);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 100%;
    cursor: pointer;
    transition: all .15s ease-in-out
}

.sidebar__toggle:hover {
    color: var(--kt-text-primary);
}

.sidebar__toggle svg {
    transition: transform .3s ease;
}

.sidebar--small .sidebar__toggle {
    color: var(--kt-text-primary);
}

.sidebar--small .sidebar__toggle svg {
    transform: rotate(180deg);
}
