.batchdispersionstate {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.batchdispersionstate__transactionReports {
    width: fit-content;
    position: relative;
}

.batchdispersionstate__transactionReports__options {
    background-color: var(--kt-menu-dropdown-bg-color);
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    position: absolute;
    bottom: 64px;
    padding: 1rem 0;
    margin: 0px;
    border-radius: 0.475rem;
    box-shadow: var(--kt-menu-dropdown-box-shadow);
    animation: menu-sub-dropdown-animation-fade-in .3s ease 1, menu-sub-dropdown-animation-move-up .3s ease 1;
    z-index: 3;
}

.batchdispersionstate__transactionReports--hasOptions .button::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 6 6' fill='%23fff'%3E%3Cpath d='M3.27989 3.23571L1.53642 4.97917C1.36382 5.15176 1.36383 5.43158 1.53642 5.60417C1.70901 5.77676 1.98882 5.77676 2.16139 5.60417L4.47096 3.29463C4.63371 3.13192 4.63371 2.86809 4.47096 2.70538L2.16139 0.395812C1.98883 0.22325 1.70901 0.22325 1.53642 0.395812C1.36383 0.568437 1.36383 0.84825 1.53642 1.02081L3.27989 2.76429C3.41006 2.89446 3.41006 3.10554 3.27989 3.23571Z'/%3E%3C/svg%3E");
    transform: rotate(-90deg);
    transition: transform .3s ease;
}

.batchdispersionstate__transactionReports--hasOptions.batchdispersionstate__transactionReports--open .button::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 6 6' fill='%23fff'%3E%3Cpath d='M3.27989 3.23571L1.53642 4.97917C1.36382 5.15176 1.36383 5.43158 1.53642 5.60417C1.70901 5.77676 1.98882 5.77676 2.16139 5.60417L4.47096 3.29463C4.63371 3.13192 4.63371 2.86809 4.47096 2.70538L2.16139 0.395812C1.98883 0.22325 1.70901 0.22325 1.53642 0.395812C1.36383 0.568437 1.36383 0.84825 1.53642 1.02081L3.27989 2.76429C3.41006 2.89446 3.41006 3.10554 3.27989 3.23571Z'/%3E%3C/svg%3E");
    transform: rotate(90deg);
    transition: transform .3s ease;
}

.batchdispersionstate__transactionReports--open .button {
    color: #ffffff;
    background-color: #0095e8;
    box-shadow: none;
}

.batchdispersionstate__transactionReports__option {
    color: var(--kt-gray-800);
    font-weight: 500;
    padding: 0.8rem 2rem;
    cursor: pointer;
    transition: color .2s ease;
}

.batchdispersionstate__transactionReports__option:hover {
    background-color: var(--kt-primary-light);
    color: var(--kt-primary);
}

.statusHistoryTrigger {
    display: flex;
    gap: 0.5rem;
    background-color: transparent;
    border: none;
    width: fit-content;
    color: #B5B5C3;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &:hover, &.active {
        color: var(--kt-primary);
    }
}

.statusHistoryTimeline__wrapper {
    margin-top: 8px;
}