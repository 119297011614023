.menu {
    position: relative;
}

.menu__icon {
    color: var(--kt-text-gray-400);
    display: grid;
    place-items: center;
    cursor: pointer;
    transition:
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
}

.menu__icon:hover {
    color: var(--kt-text-primary);
}

.menu--open .menu__icon {
    color: var(--kt-text-primary);
}

.wrapper__menu {
    width: 200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    will-change: transform;
    inset: 0px 0px auto auto;
    padding: 0;
    margin: 0;
    border-radius: 0.475rem;
    background-color: var(--kt-menu-dropdown-bg-color);
    box-shadow: var(--kt-menu-dropdown-box-shadow);
    animation:
        menu-sub-dropdown-animation-fade-in .3s ease 1,
        menu-sub-dropdown-animation-move-up .3s ease 1;
    transform: translate3d(0px, 36px, 0px);
    z-index: 100;
}

.wrapper__menu__heading {
    color: var(--kt-text-dark);
    font: var(--menu-heading-font);
    padding: 13px 19.5px;
    border-bottom: 1px solid var(--kt-border-dashed-color);
    border-radius: 0;
    cursor: auto;
}

.wrapper__menu__list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2px 9.75px;
    margin: 9.75px 0;
}

.menu__item {
    background-color: var(--kt-card-cap-bg);
    color: var(--kt-gray-800);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8.45px 9.75px;
    margin: 0;
    border-radius: 6px;
    cursor: pointer;
    transition: color .2s ease;
}

.menu__item:hover {
    background-color: var(--kt-primary-light);
    color: var(--kt-primary);
}

.menu__item--fit {
    width: fit-content;
}

.menu__item--disabled {
    opacity: 0.65;
    cursor: auto;
    pointer-events: none;
}

.menu__item__icon svg {
    fill: var(--kt-gray-800);
}

.wrapper__menu--submenu {
    background-color: var(--kt-menu-dropdown-bg-color);
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    top: -40px;
    left: -200px;
    padding: 9.75px;
}

.menu--disabled, .menu--disabled :not(.wrapper__menu) {
    opacity: 0.65;
    cursor: auto;
    pointer-events: none;
}

@keyframes menu-sub-dropdown-animation-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes menu-sub-dropdown-animation-move-up {
    0% {
        margin-top: 0.75rem
    }
    100% {
        margin-top: 0;
    }
}
