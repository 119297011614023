.footer {
    height: 60px;
    min-height: 60px;
    background-color: #fff;
    margin-top: auto;
}

.footer_container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
}

.footer__copyright {
    color: #A1A5B7;
    font-family: Inter,Helvetica,sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
}

.footer__link {
    color: #7E8299;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    padding: 8px 6px;
    transition: color .2s ease;
    cursor: pointer;
}

.footer__link:hover {
    color: #009ef7;
}