.notification {
  color: white !important;
  font-size: 14px !important;
  width: 370px !important;
  margin-bottom: 10px !important;
}

.notification--info {
  background-color: #009ef7 !important;
}

.notification--warning {
  background-color: #f1bc00 !important;
}

.notification--error {
  background-color: #ff4d4f !important;
}

.notification--success {
  background-color: #00a854 !important;
}
