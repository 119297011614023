.main {
    background-color: #f5f8fa;
    height: 100%;
    margin: 70px 0 0 0;
}

.main_container {
    padding: 20px 30px;
}

.actioncomponent {
    pointer-events: all;
}

.App .main--notreadable .actioncomponent,
.App .main--notreadable .actioncomponent:hover,
.App .main--notreadable .actioncomponent:active,
.App .main--notreadable .actioncomponent:focus {
    display: none;
}

.App .main--notexecutable .actioncomponent,
.App .main--notexecutable .actioncomponent:hover,
.App .main--notexecutable .actioncomponent:active,
.App .main--notexecutable .actioncomponent:focus {
    pointer-events: none;
    cursor: auto;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .main {
        margin: 60px 0 0 0;
    }
    
    .main_container {
        padding: 20px;
    }
}
/* End of Responsive Design */
