.dropdown {
    background-color: var(--kt-primary-light);
    color: var(--kt-text-gray-700);
    width: -moz-fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border: 0;
    border-radius: 5.5px;
    cursor: pointer;
    transition:
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
}

.dropdown:focus,
.dropdown:focus-visible {
    outline: none;
}

.dropdown__value {
    color: var(--kt-text-gray-600);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dropdown__value svg {
    fill: var(--kt-text-gray-600);
    position: absolute;
    right: -5px;
    transform: rotate(90deg);
    transition: transform .3s ease;
}

.dropdown--open {
    background-color: var(--kt-input-solid-bg-focus);
}

.dropdown--open .dropdown__value svg {
    transform: rotate(-90deg);
}

.dropdown--small {
    height: 35px;
    padding: 8px 12px;
}

.dropdown--small .dropdown__value {
    font: var(--dropdown-font);
}

.dropdown--regular {
    height: 45px;
    padding: 8px 12px;
}

.dropdown--regular .dropdown__value {
    font: var(--dropdown-font);
}

.dropdown--large {
    height: 50px;
    padding: 0.775rem 1rem;
}

.dropdown--large .dropdown__value {
    font: var(--dropdown-large-font);
}

.dropdown--centered .dropdown__value {
    justify-content: center;
}

.dropdown--fit {
    width: fit-content;
    min-width: 90px;
}

.dropdown--disabled {
    opacity: 0.65;
    cursor: auto;
    pointer-events: none;
}

.dropdown__options {
    background-color: var(--kt-body-bg);
    list-style: none;
    width: 100%;
    max-height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: var(--kt-dropdown-box-shadow);
    z-index: 2;
    transform: translateY(100%);
    transition: all .2s ease-in-out;
}

.dropdown--open .dropdown__options {
    max-height: 260px;
    padding: 13px 0;
}

.dropdown__option {
    color: var(--kt-text-gray-600);
    font: var(--dropdown-option-font);
    font-size: 13px;
    font-weight: 500;
    padding: 9px 22.5px;
    cursor: pointer;
    transition: color .2s ease;
}

.dropdown__option:hover {
    background-color: var(--kt-component-hover-bg);
    color: var(--kt-component-hover-color);
}

.dropdown__option:focus,
.dropdown__option:target {
    background-color: var(--kt-component-active-bg);
    color: var(--kt-component-active-color);
}
