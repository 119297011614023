.sidebar {
    width: 265px;
    min-width: 265px;
    min-height: 100%;
    background-color: #1e1e2d;
    z-index: 3;
    transition: all .3s ease-in-out;
}

.sidebar--small {
    width: 75px;
    min-width: 75px;
}

.sidebar--toggle {
    width: 265px;
    min-width: 265px;
}

/* Sidebar Menu Body */
.sidebar__body {
    margin: 16px 0;
}

.sidebar__body_container {
    height: calc(100vh - 70px - 32px);
    overflow-y: auto;
    overflow-x: hidden;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 0;
}

/* width */
.sidebar__body_container::-webkit-scrollbar {
    width: var(--kt-scrollbar-size);
    border-radius: 20px;
}

/* Track */
.sidebar__body_container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

/* Handle */
.sidebar__body_container::-webkit-scrollbar-thumb {
    background-color: #B5B5C3;
    border-radius: 20px;
}

/* Handle on hover */
.sidebar__body_container::-webkit-scrollbar-thumb:hover {
    background-color: #9d9da6;
}

.sidebar__body__group {
    padding: 0.15rem 0.75rem;
}

.sidebar__body__group__title {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.65rem 1rem;
    cursor: pointer;
}

.sidebar__body__group__title--disabled {
    cursor: auto;
}

.sidebar__body__group__title__icon {
    width: 2rem;
    min-width: 2rem;
    display: grid;
    place-items: center;
}

.sidebar__body__group__title__text {
    opacity: 1;
    width: 168px;
    max-width: 168px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: opacity .3s ease;
}

.sidebar--small
.sidebar__body__group__title__text {
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;
}

.sidebar--toggle
.sidebar__body__group__title__text {
    opacity: 1;
    transition: opacity .3s ease;
    pointer-events: all;
}

.sidebar__body__group__title__arrow {
    transition: all .15s ease-in-out;
    transform: rotate(90deg);
}

.sidebar--small
.sidebar__body__group__title__arrow {
    opacity: 0;
    pointer-events: none;
}

.sidebar--toggle
.sidebar__body__group__title__arrow {
    opacity: 1;
    pointer-events: all;
}

.sidebar__body__group__title--disabled
.sidebar__body__group__title__arrow {
    display: none;
}

.sidebar__body__group__title--open
.sidebar__body__group__title__arrow {
    transform: rotate(-90deg);
}

.sidebar__body__group__items {
    display: flex;
    flex-direction: column;
    gap: 2px;
    opacity: 1;
    margin-left: 1rem;
}

.sidebar--small
.sidebar__body__group__items {
    opacity: 0;
    transition: opacity .3s ease;
    pointer-events: none;
}

.sidebar--toggle
.sidebar__body__group__items {
    display: flex;
    opacity: 1;
    transition: opacity .3s ease;
    pointer-events: all;
}

.sidebar__body__group__item a {
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 0.65rem 1rem;
    margin: 0.15rem 0;
    border-radius: 6px;
    cursor: pointer;
    transition: color .2s ease;
}


.sidebar__body__group__item--disabled a {
    cursor: auto;
}

.sidebar__body__group__item a::before {
    content: "";
    width: 4px;
    min-width: 4px;
    height: 4px;
    min-height: 4px;
    background-color: #9d9da6;
    display: block;
    margin: 0 0.95rem 0 0.55rem;
    border-radius: 100%;
    transition: background-color .2s ease;
}

.sidebar__body__group__item__text {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #9d9da6;
    white-space: nowrap;
}

.sidebar__body__group__item a:hover::before {
    background-color: var(--kt-primary-inverse);
}


.sidebar__body__group__item--disabled a:hover::before {
    background-color: #9d9da6;
}

.sidebar__body__group__item a:hover
.sidebar__body__group__item__text {
    color: var(--kt-primary-inverse);
}

.sidebar__body__group__item--disabled a:hover
.sidebar__body__group__item__text {
    color: #9d9da6;
}

.sidebar__body__group__item--active a {
    background-color: #2a2a3c;
    margin-right: 1rem;
}

.sidebar__body__group__item--active a::before {
    background-color: var(--kt-primary-inverse);
}

.sidebar__body__group__item--active
.sidebar__body__group__item__text {
    color: var(--kt-primary-inverse);
}
/* End of Sidebar Menu Body */

/* Responsive Design */
@media (max-width: 1024px) {
    .sidebar {
        background-color: #1e1e2d;
        width: 225px;
        min-width: 225px;
        left: 0;
        margin: 0;
        border-left: var(--kt-app-sidebar-base-border-start-mobile);
        border-right: 0;
        transform: translateX(-100%);
        box-shadow: var(--kt-app-sidebar-base-box-shadow-mobile);
        position: fixed;
        top: 0;
        bottom: 0;
        transition: transform .3s ease-in-out;
        z-index: 5;
    }

    .sidebar--open {
        transform: none;
        box-shadow: var(--kt-drawer-box-shadow);
        transition: transform .3s ease-in-out;
    }

    .sidebar--small .sidebar__body__group__items {
        opacity: 1;
        pointer-events: auto;
    }

    @keyframes animation-drawer-fade-in {
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
        }
    }

    .sidebar__header {
        display: none
    }

    .sidebar__body_container {
        height: calc(100vh - 32px);
    }

    .sidebar__body__group__title__text {
        opacity: 1 !important;
    }

    .sidebar__body__group__title__arrow {
        opacity: 1 !important;
    }
}
/* End of Responsive Design */
