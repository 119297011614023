.batchuploadfile {
    border-bottom: 1px dashed #f1f1f1;
    transition: opacity .2s ease;
    pointer-events: all;
    cursor: pointer;
    position: relative;
}

.batchuploadfile--selected::before {
    content: "";
    background-color: var(--kt-success);
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 6px;
    z-index: 1;
}

.batchuploadfile--blue.batchuploadfile--selected::before {
    background-color: var(--kt-text-primary);
}

.batchuploadfile--yellow.batchuploadfile--selected::before {
    background-color: var(--kt-text-warning);
}

.batchuploadfile--red.batchuploadfile--selected::before {
    background-color: var(--kt-text-danger);
}

.batchuploadfile--green.batchuploadfile--selected::before {
    background-color: var(--kt-success);
}

.batchuploadfile:hover {
    opacity: 0.65;
}

.batchuploadfile:last-child {
    border: 0;
}

.batchuploadfile_container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 0;
}

.batchuploadfile:first-child
.batchuploadfile_container {
    padding: 0 0 16px 0;
}

.batchuploadfile:last-child
.batchuploadfile_container {
    padding: 16px 0;
}

.batchuploadfile__icon {
    background-color: rgb(245, 248, 250);
    min-width: 40px;
    height: 40px;
    position: relative;
    display: grid;
    place-items: center;
    border-radius: 6px;
    cursor: pointer;
}

.batchuploadfile__icon::before {
    background-color: var(--kt-success);
    position: absolute;
    left: 0;
    content: "";
    display: block;
    width: 40px;
    height: 100%;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.batchuploadfile__icon
.fonticon {
    color: rgb(245, 248, 250);
    font-size: 22.75px;
    font-weight: 500;
    line-height: 22.75px;
    display: grid;
    place-items: center;
    transition: opacity .2s ease;
    z-index: 1;
}

.batchuploadfile__icon
.fonticon:hover {
    opacity: 0.65;
}

.batchuploadfile__icon
.fonticon::before {
    content: "\f113";
}

.batchuploadfile--blue
.batchuploadfile__icon::before {
    background-color: var(--kt-text-primary);
}

.batchuploadfile--blue
.batchuploadfile__icon
.fonticon {
    color: #fff;
}

.batchuploadfile--yellow
.batchuploadfile__icon::before {
    background-color: var(--kt-text-warning);
}

.batchuploadfile--yellow
.batchuploadfile__icon
.fonticon {
    color: #fff;
}

.batchuploadfile--red
.batchuploadfile__icon::before {
    background-color: rgb(245, 248, 250);
}

.batchuploadfile--red
.batchuploadfile__icon
.fonticon {
    color: var(--kt-text-danger);
}

.batchuploadfile--green
.batchuploadfile__icon::before {
    background-color: rgb(245, 248, 250);
}

.batchuploadfile--green
.batchuploadfile__icon
.fonticon {
    color: #50cd89;
}

.batchuploadfile--selected
.batchuploadfile__icon {
    background-color: var(--kt-white);
    position: relative;
}

.batchuploadfile--selected
.batchuploadfile__icon::before {
    background-color: var(--kt-success);
    position: absolute;
    left: 0;
    content: "";
    display: block;
    width: 0px;
    height: 100%;
    border-radius: 6px 0 0 6px;
}

.batchuploadfile--selected
.batchuploadfile__icon svg * {
    fill: var(--kt-success);
}

.batchuploadfile--blue.batchuploadfile--selected
.batchuploadfile__icon svg * {
    fill: #009ef7;
}

.batchuploadfile--blue.batchuploadfile--selected
.batchuploadfile__icon::before {
    background-color: #009ef7;
}

.batchuploadfile--yellow.batchuploadfile--selected
.batchuploadfile__icon svg * {
    fill: var(--kt-text-warning);
}

.batchuploadfile--yellow.batchuploadfile--selected
.batchuploadfile__icon::before {
    background-color: var(--kt-text-warning);
}

.batchuploadfile--red.batchuploadfile--selected
.batchuploadfile__icon svg * {
    fill: var(--kt-text-danger);
}

.batchuploadfile--red.batchuploadfile--selected
.batchuploadfile__icon::before {
    background-color: var(--kt-text-danger);
}

.batchuploadfile--green.batchuploadfile--selected
.batchuploadfile__icon svg * {
    fill: var(--kt-success);
}

.batchuploadfile--green.batchuploadfile--selected
.batchuploadfile__icon::before {
    background-color: var(--kt-success);
}

.batchuploadfile__data {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.batchuploadfile__data__name {
    color: #3F4254;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.batchuploadfile__data__status_date {
    display: flex;
    align-items: center;
    gap: 4px;
}

.batchuploadfile__data__date {
    overflow: hidden;
    white-space: nowrap;
    color: #B5B5C3;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}


.batchuploadfile__status {
    min-width: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    gap: .5rem;

    .batchuploadfile__company {
        color: #B5B5C3;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }
}

.timeline-wrapper {
    margin-left: 12px;

    .timelineitem {
        .timelineitem__content {
            margin-bottom: 5px;
        }
        
        .timelineitem__label {
            p {
                color: var(--kt-text-gray-800);
                font-weight: 600;
                font-size: 13px;
            }
    
            span {
                color: var(--kt-text-gray-400);
                font-weight: 500;
                font-size: 12px;
            }
        }
    }
}

/* .batchuploadfile__records__number {
    color: #3F4254;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.batchuploadfile__records__label {
    color: #B5B5C3;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
} */

/* Responsive Design */

/* End of Responsive Design */