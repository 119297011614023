.dashboard {
    min-height: 100vh;
}

.dashboard_container {
    height: 100%;
    min-height: 100vh;
    display: flex;
}

.dashboard__content {
    width: calc(100% - 265px);
    display: flex;
    flex-direction: column;
}

.sidebar--small ~ .dashboard__content {
    width: calc(100% - 75px);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .dashboard__content {
        width: 100%;
    }

    .dashboard__content__main__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
/* End of Responsive Design */
