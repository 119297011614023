.invoicedetail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 4rem;
    padding: 0 2.5rem;
}

.invoicedetail__principal {
    flex: 1 1 auto;
}

.invoicedetail__principal_container {
    display: flex;
    flex-direction: column;
}

.invoicedetail__principal__title {
    color: var(--kt-text-gray-800);
    font-size: calc(1.26rem + .12vw);
    font-weight: 600;
    margin: 0;
}

.invoicedetail__principal__datalist {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 2rem 0 4rem 0;
}

.invoicedetail__client {
    min-width: 350px;
    flex: 1 1 auto;
    border: 1px dashed var(--kt-gray-300);
    border-radius: 0.625rem;
}

.invoicedetail__client_container {
    padding: 2.25rem;
}

.invoicedetail__client__title {
    color: var(--kt-text-gray-600);
    font-size: 1.075rem;
    font-weight: 700;
    margin: 0 0 2rem 0;
}

.invoicedetail__client__datalist {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.invoicedetail__dataelement {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.invoicedetail__dataelement h4 {
    color: var(--kt-text-gray-600);
    font-weight: 500;
}

.invoicedetail__dataelement p,
.invoicedetail__dataelement .link__text {
    color: var(--kt-text-gray-800);
    font-size: 1.075rem;
    font-weight: 600;

}

.invoicedetail__principal_container
.link__text {
    width: 100%;
    color: #009ef7;
    transition: color .2s ease;
}

.invoicedetail__dataelement .link__text:hover {
    color: #009ef7;
}

.invoicedetail__principal__total {
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2rem;
}

.invoicedetail__transactionReport {
    color: var(--bs-success);
    font-size: 1.075rem;
    font-weight: 600;
    text-decoration: none;
    transition: opacity .2s ease;
    cursor: pointer;
}

.invoicedetail__transactionReport:hover {
    opacity: 0.75;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .invoiceDetailView .card__header__subtitle {
        display: none
    }

    .invoicedetail {
        padding: 0;
    }

    .invoicedetail__principal__title {
        margin: 0 0 4px 0;
    }

    .invoicedetail__client {
        min-width: auto;
    }

    .invoicedetail__client_container {
        padding: 1.25rem;
    }
}
/* End of Responsive Design */
