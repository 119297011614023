.code {
    display: flex;
    align-items: center;
    gap: 4px;
}

.code__label {
    color: var(--bs-text-gray-700);
    font-size: 1.075rem;
    font-weight: 400;
}

.code__text {
    background-color: var(--bs-code-bg);
    color: var(--bs-code-color);
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--bs-font-monospace);
    line-height: inherit;
    padding: 0.1rem 0.4rem;
    margin: 0;
    border: 1px solid var(--bs-code-border-color);
    border-radius: 0.3rem;
    box-shadow: 0 3px 9px rgba(0,0,0,.08);
    word-wrap: break-word;
}
