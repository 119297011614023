.filter {
    color: var(--kt-text-gray-700);
    position: relative;
    box-shadow: none;
}

.filter__modal {
    width: 300px;
    background-color: var(--kt-menu-dropdown-bg-color);
    z-index: 100;
    position: absolute;
    inset: 35px 0px auto auto;
    padding: 0;
    margin: 0px;
    border-radius: 0.475rem;
    box-shadow: var(--kt-menu-dropdown-box-shadow);
    will-change: transform;
    animation: menu-sub-dropdown-animation-fade-in .3s ease 1, menu-sub-dropdown-animation-move-up .3s ease 1;
}

.filter__title {
    color: var(--kt-text-dark);
    display: block;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.25rem 1.75rem;
    border-bottom: 1px solid var(--kt-gray-200);
}

.filter__content {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 1.25rem 1.75rem;
}

.filter__option {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.filter__option__label {
    color: var(--kt-form-label-color);
    font-size: 1.05rem;
    font-weight: 500;
}

.filter__buttons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0 0 auto;
}
