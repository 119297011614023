.datepicker  {
    background-color: var(--kt-primary-light);
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.775rem 1rem;
    border: 1px solid var(--bs-gray-100);;
    border-radius: 0.475rem;
    box-shadow: none;
    cursor: pointer;
    transition: color .2s ease;
}

.flatpickr-input {
    background-color: transparent;
    color: var(--kt-light-inverse);
    font: var(--datepicker-font);
    border: 0;
    width: 110%;
    padding-right: 5px;
}

.datepicker:hover .flatpickr-input,
.flatpickr-input:hover,
.flatpickr-input:hover::placeholder,
.flatpickr-input.active {
    color: var(--kt-text-primary);
}

.flatpickr-input::placeholder {
    color: var(--kt-light-inverse);
    transition: color .2s ease;
}

.flatpickr-calendar {
    width: fit-content;
    background-color: var(--bs-body-bg);
    box-shadow: var(--bs-dropdown-box-shadow);
}

.flatpickr-calendar.animate.open {
    padding: 1rem;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background-color: var(--bs-body-bg);
    font-size: 1rem;
    color: var(--bs-gray-700);
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    outline: none;
    border: 0;
    border-radius: 0.475rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: var(--bs-gray-100);
}

.flatpickr-current-month .numInputWrapper {
    width: fit-content;
}

.flatpickr-current-month .numInputWrapper:hover {
    background: transparent;
}

.flatpickr-current-month input.cur-year {
    max-width: 60px;
    background: transparent;
    color: var(--bs-gray-700);
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    outline: 0;
}

.flatpickr-current-month {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    top: 1rem;
    left: 1rem;
    transition: all .2s ease;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    top: 1rem;
    right: 1rem;
    transition: all .2s ease;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month:hover,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month:hover {
    background-color: var(--bs-gray-100);
    color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month:hover svg {
    fill: var(--bs-gray-700);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month svg,
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month svg,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month svg {
    fill: var(--bs-gray-500);
    height: 13px;
    width: 13px;
}

.flatpickr-rContainer {
    width: 231px;
}

.flatpickr-days {
    width: 231px;
}

span.flatpickr-weekday {
    width: 33px;
    min-width: 33px;
    max-width: 33px;
    height: 33px;
    color: var(--bs-gray-800);
    display: grid;
    place-items: center;
    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
}

.rangeMode .dayContainer {
    width: 231px;
    min-width: 231px;
    max-width: 231px;
}

.rangeMode .flatpickr-day {
    width: 33px;
    min-width: 33px;
    max-width: 33px;
    color: var(--bs-gray-700);
    display: block;
    font-size: 1rem;
    font-weight: 400;
    border: 0;
    border-radius: 0.475rem;
    padding: 1px;
    margin: 0;
    box-shadow: none;
}

.rangeMode .flatpickr-day:hover {
    background-color: var(--bs-component-hover-bg);
    color: var(--bs-component-hover-color);
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
    background-color: var(--bs-component-active-bg);
    color: var(--bs-component-active-color);
    border-radius: 0.475rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
    background-color: var(--bs-component-active-bg);
    color: var(--bs-component-active-color);
    border-radius: 0.475rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rangeMode .flatpickr-day.inRange {
    background-color: var(--bs-component-hover-bg);
    color: var(--bs-component-hover-color);
    border-color: transparent;
    border-radius: 0;
}

.flatpickr-day.today {
    background: var(--bs-gray-200);
    color: var(--bs-gray-700);
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
    color: var(--bs-gray-500);
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
    border-radius: 0.475rem;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    box-shadow: none;
}

.datepicker--disabled,
.datepicker--disabled:hover {
    opacity: var(--kt-btn-disabled-opacity);
    cursor: auto !important;
    pointer-events: none !important;
}
