.permittedcomponent--notreadable,
.permittedcomponent--notreadable > * {
    display: none !important;
}

.permittedcomponent--notexecutable,
.permittedcomponent--notexecutable > * {
    pointer-events: none !important;
    cursor: auto !important;
}
