.wrapper_progressBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.progressBar__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto auto 0.5rem auto;
}

.progressBar__title {
    color: var(--kt-text-gray-400);    ;
    font-size: 1.075rem;
    font-weight: 500;
}

.progressBar__value {
    font-size: 1.075rem;
    font-weight: 600;
}

.progressBar__body {
    background-color: var(--kt-light);
    width: 100%;
    height: 5px;
    margin: 0;
}

.progressBar__bar {
    background-color: var(--kt-success);
    height: 5px;
    border-radius: 0.475rem;
}
