.customerDetail .view__body {
    display: flex;
    gap: 32px;
}

.customerDetail__body__left {
    width: 365px;
}

.customerDetail__body__right {
    width: calc(100% - 365px);
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.customerDetail__bankAccounts {
    overflow-y: auto;
    max-height: 232px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

/* width */
.customerDetail__bankAccounts::-webkit-scrollbar {
    width: var(--kt-scrollbar-size);
    height: var(--kt-scrollbar-size);
    border-radius: 20px;
}

/* Track */
.customerDetail__bankAccounts::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

/* Handle */
.customerDetail__bankAccounts::-webkit-scrollbar-thumb {
    background-color: var(--kt-scrollbar-color);
    border-radius: 20px;
}

/* Handle on hover */
.customerDetail__bankAccounts::-webkit-scrollbar-thumb:hover {
    background-color: var(--kt-scrollbar-hover-color);
}

.customerDetail__bankAccount {
    padding: 0 0 0.75rem 0;
    border-bottom: 1px dashed var(--kt-border-dashed-color);
}

.customerDetail__bankAccount:last-child {
    border-bottom: 0;
}

/* Customer Detail Profile */
.customerDetail__profile {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.customerDetail__profile__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px dashed var(--kt-border-dashed-color);
    padding: 0 0 1.25rem 0;
}

.customerDetail__profile__name {
    color: var(--kt-text-gray-800);
    font-size: calc(1.26rem + .12vw);
    font-weight: 600;
    text-align: center;
    margin: 1.75rem 0 .25rem 0;
}

.customerDetail__profile__email {
    color: var(--kt-text-muted);
    font-size: 1.15rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0 1.5rem 0;
}

.customerDetail__profile__data {
    display: flex;
    flex-direction: column;
}

.customerDetail__profile__data__label {
    font-size: 1.075rem;
    font-weight: 600;
    margin: 1.25rem 0 0 0;
}

.customerDetail__profile__data__content {
    min-height: 21px;
    color: var(--kt-text-gray-600);
    font-size: 1.075rem;
}
/* End of Customer Detail Profile */

/* Responsive Design */
@media (max-width: 1024px) {
    .customerDetail .view__body {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .customerDetail__body__left {
        width: 100%;
    }

    .customerDetail__body__right {
        width: 100%;
        gap: 20px;
    }
}
/* End of Responsive Design */
