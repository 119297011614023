.errorpage {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/bg-error.jpg");
    width: 100vw;
    height: 100vh;
}

.errorpage_container {
    height: 100%;
    display: grid;
    place-items: center;
}

.errorpage__card {
    background-color: var(--kt-card-bg);
    width: 650px;
    padding: 1.25rem 0;
    border: 0;
    border-radius: var(--bs-card-border-radius);
    box-shadow: var(--kt-card-box-shadow);
}

.errorpage__card_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.5rem 2.25rem;
}

.errorpage__card__title {
    color: var(--kt-text-gray-900);
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 700;
    margin-bottom: 1rem;
}

.errorpage__card__description {
    color: var(--kt-text-gray-500);
    font-size: 1.075rem;
    font-weight: 500;
    margin-bottom: 1.75rem;
}

.errorpage__card_container img {
    margin-bottom: 0.75rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .errorpage__card {
        width: fit-content;
    }
}
/* End of Responsive Design */
