.invoicesView .card__header__title__children {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.invoicesView .card__header__title__children {
    width: 100%;
}

.invoicesView .wrapper_inputfield  {
    width: fit-content;
}

.invoicesView__body__headerChildren__right {
    display: flex;
    align-items: center;
    gap: 8px;
}

.invoicesView .filter__option--input {
    display: none;
}