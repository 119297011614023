.link {
    width: fit-content;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.link--disabled {
    cursor: auto;
    pointer-events: none;
}

.link__text {
    width: 100%;
    color: #009ef7;
    font: var(--link-font);
    text-decoration: none;
    transition: opacity .2s ease;
}

.link__text:hover {
    opacity: 0.75;
}
