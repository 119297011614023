.menu-button {
  border: none;
  background-color: transparent;
  color: var(--kt-text-gray-400);
  display: grid;
  place-items: center;
  cursor: pointer;
  transition:
      color .15s ease-in-out,
      background-color .15s ease-in-out,
      border-color .15s ease-in-out,
      box-shadow .15s ease-in-out;
}

.menu-button:hover {
  color: var(--kt-text-primary);
}

.menu-button--open .menu-button {
  color: var(--kt-text-primary);
}
