.wrapper_loader {
    width: 100%;
    min-width: 100%;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow-wrap: inherit;
    display: grid;
    place-items: center;
    z-index: 1;
    opacity: 1;
    cursor: auto;
    pointer-events: auto;
}

.wrapper_loader--loading {
    width: 100%;
    min-width: 100%;
    height: 100%;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow-wrap: inherit;
    display: grid;
    place-items: center;
    z-index: 1;
    opacity: 0.5 !important;
    cursor: none !important;
    pointer-events: none !important;   
}

.wrapper_loader--loading * {
    cursor: none !important;
    pointer-events: none !important;
}

.loader {
    position: absolute;
    color: var(--kt-btn-link-disabled-color);
    font-size: 10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
}

.wrapper_loader .loader {
    display: none;
}
  
 @keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 
            2em -2em 0 0em, 3em 0 0 -1em, 
            2em 2em 0 -1em, 0 3em 0 -1em, 
            -2em 2em 0 -1em, -3em 0 0 -1em, 
            -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
            3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
            -2em 2em 0 -1em, -3em 0 0 -1em, 
            -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 
            2em -2em 0 0, 3em 0 0 0.2em, 
            2em 2em 0 0, 0 3em 0 -1em, 
            -2em 2em 0 -1em, -3em 0 0 -1em, 
            -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
            -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
            -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
            -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
            3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
            -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
            3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
            -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}
