.badge {
    border-radius: var(--bs-badge-border-radius);
}

.badge--small {
    width: fit-content;
    font: var(--badge-small-font);
    padding: 4px 6px;
}

.badge--regular {
    width: fit-content;
    font: var(--badge-regular-font);
    padding: 4px 6px;
}

.badge--large {
    font: var(--badge-large-font);
    padding: 0.75rem 1rem;
}

.badge--gray {
    background-color: var(--kt-primary-light);
    color: var(--kt-light-inverse);
}

.badge--yellow {
    color: var(--kt-warning);
    background-color: var(--kt-warning-light);
}

.badge--blue {
    color: var(--kt-primary);
    background-color: var(--kt-primary-light);
}

.badge--red {
    color: var(--kt-danger);
    background-color: var(--kt-danger-light);
}

.badge--green {
    color: var(--kt-success);
    background-color: var(--kt-success-light);
}
