.wrapper_inputfield {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.inputfield {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.775rem 1rem;
    border-radius: 0.475rem;
    box-shadow: none!important;
    transition: color .2s ease;
}

.inputfield__input {
    width: 100%;
    background-color: transparent;
    font: var(--inputfield-font);
    border: none;
}

.inputfield__input::-webkit-input-placeholder {
    opacity: 0.65;
}

.inputfield__icon {
    display: grid;
    place-items: center;
}

.inputfield--primary {
    background-color: var(--kt-input-solid-bg);
    border: 1px solid var(--kt-input-solid-bg);
}

.inputfield--primary
.inputfield__input {
    color: var(--kt-input-solid-color);
}

.inputfield--primary:focus {
    background-color: var(--kt-input-solid-bg-focus);
    border-color: var(--kt-input-solid-bg-focus);
    transition: color .2s ease;
}

.inputfield--primary:focus
.inputfield__input {
    color: var(--kt-input-solid-color);
}

.inputfield--secondary {
    background-color: var(--kt-input-bg);
    border: 1px solid var(--kt-input-border-color);
}

.inputfield--secondary
.inputfield__input {
    color: var(--kt-input-color);
}

.inputfield--secondary:focus {
    background-color: var(--kt-input-focus-bg);
    border-color: var(--kt-input-focus-border-color);
    transition: color .2s ease;
}

.inputfield--secondary:focus
.inputfield__input {
    color: var(--kt-input-focus-color);
}

.inputfield__error {
    opacity: 0;
    color: #f1416c;
    font-size: .925rem;
    font-weight: 400;
    margin: 0.5rem 0 0 0;
}

.wrapper_inputfield--error
.inputfield__error {
    opacity: 1;
}

.inputfield__input:focus ~ .inputfield__error,
.inputfieldpassword__input .inputfield__input:focus ~ .inputfield__error {
    opacity: 0;
}