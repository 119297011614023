.timeline .timelineitem {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
}

.timeline .timelineitem__line {
    width: 40px;
    height: 100%;
    display: block;
    content: " ";
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 10px 0 -40px 0;
    transform: translate(20%);
    border-left: 1px dashed var(--kt-gray-300);
}

.timeline .wrapper_timelineitem:last-child .timelineitem__line {
    height: auto;
    bottom: 100%;
}

.timeline .timelineitem__icon {
    height: 16px;
    background-color: #fff;
    z-index: 1;
    flex-shrink: 0;
    margin-right: 1rem;
}

.timelineitem__icon svg {
    width: 16px;
    height: 16px;
    color: var(--kt-text-warning);
}

.timelineitem__icon--blue svg {
    color: var(--kt-text-primary);
}

.timelineitem__icon--red svg {
    color: var(--kt-text-danger);
}

.timelineitem__icon--green svg {
    color: var(--kt-text-success);
}

.timeline .timelineitem__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex: 2;
    margin-bottom: 1.5rem;
}

.timelineitem__label {
    width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.timelineitem__label p {
    color: var(--kt-text-gray-400);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}

.timelineitem__label span {
    color: var(--kt-text-gray-800);
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
}

.timelineitem__result {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.timelineitem__result svg {
    min-width: fit-content;
    min-height: fit-content;
}

.timelineitem__result__total {
    display: flex;
    flex-direction: column;
}

.timelineitem__result__total span {
    color: var(--kt-text-gray-400);
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
}

.timelineitem__result__total p {
    color: var(--kt-text-gray-800);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    min-width: max-content;
}

.timeline.timeline-center .timelineitem {
    align-items: center;
}

.timeline.timeline-center .timelineitem:first-child .timelineitem__line {
    top: 50%;
}

.timeline.timeline-center .timelineitem:last-child .timelineitem__line {
    bottom: 50%;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .timeline .timelineitem__content {
        justify-content: flex-start;
        gap: 16px;
    }
}
/* End of Responsive Design */
