.inputfieldpassword {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.inputfieldpassword__input {
    background-color: var(--kt-input-solid-bg);
    width: 100%;
    height: 50px;
    min-height: calc(1.5em + 1.65rem + 2px);
    color: var(--kt-input-solid-color);
    font: var(--inputfield-font);
    padding: 0.775rem 1rem;
    border: 1px solid var(--kt-input-solid-bg);
    border-radius: 0.625rem;
    box-shadow: none!important;
    transition: color .2s ease;
}

.inputfieldpassword__input::-webkit-input-placeholder {
    opacity: 0.65;
}

.inputfieldpassword__input:focus {
    background-color: var(--kt-input-solid-bg-focus);
    border-color: var(--kt-input-solid-bg-focus);
    color: var(--kt-input-solid-color);
    transition: color .2s ease;
}

.inputfieldpassword__input__icon {
    position: absolute;
    display: grid;
    place-items: center;
    right: 19.5px;
    top: 13px;
    cursor: pointer;
}

.inputfieldpassword__bars {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.inputfieldpassword__bar {
    --kt-bg-rgb-color: var(--kt-secondary-rgb);
    background-color: var(--kt-secondary);
    height: 5px;
    flex-grow: 1;
    border-radius: 0.475rem;
}

.inputfieldpassword__bar--active {
    background-color: var(--kt-success);
}

.inputfieldpassword__text {
    color: var(--kt-text-muted);
}
