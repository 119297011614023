.vis-timeline {
  border: 1px solid var(--bs-border-color) !important;
  border-radius: .475rem;

  .vis-panel {
    border-color: var(--bs-gray-200) !important;
  }

  .vis-text {
    color: var(--bs-gray-400) !important;
    text-transform: uppercase;
    font-size: .95rem;
     
    & div {
      font-size: .95rem;
    }
  }

  .vis-item {
    color: var(--bs-gray-700);
    border-color: var(--bs-primary);
    background-color: var(--bs-gray-100);
    border-radius: .475rem !important;
  
    .vis-item-content {
      padding: .65rem 1rem;
      width: 100%;
      transform: none;
    }
  }

  .vis-item.vis-selected {
    background-color: var(--bs-warning-light);
    border-color: var(--bs-warning);
    z-index: 2;
  }

  .vis-time-axis .vis-grid.vis-minor {
    border-color: var(--bs-border-color);
  }
}
