.navitem {
    width: 80px;
    height: 80px;
    position: relative;
    display: grid;
    place-items: center;
    border: 1px dashed var(--kt-border-dashed-color);
    border-radius: 8px;
    cursor: pointer;
}

.navitem--selected {
    border: 1px solid var(--kt-border-dashed-color);
}

.navitem--disabled {
    opacity: 0.65;
    cursor: auto;
    pointer-events: none;
}

.navitem_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.navitem__icon {
    min-height: 30px;
    display: grid;
    place-items: center;
}

.navitem__name {
    color: #3F4254;
    font: var(--navitem-font);
}

.navitem__bullet {
    display: none
}

.navitem--selected .navitem__bullet {
    display: block;
    width: 100%;
    height: 4px;
    background-color: #009ef7;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 8px 8px;
}
