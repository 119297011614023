.overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 4;
    background-color: var(--kt-drawer-overlay-bg-color);
    animation: animation-drawer-fade-in .3s ease-in-out 1;
    overflow: hidden;
}
