.wrapper_table {
    position: relative;
    max-height: calc(100vh - 356px);
    overflow-wrap: break-word;
    overflow-y: auto;
    overflow-x: auto;
    display: block;
    text-size-adjust: 100%;
}

/* width */
.wrapper_table::-webkit-scrollbar {
    width: var(--kt-scrollbar-size);
    height: var(--kt-scrollbar-size);
    border-radius: 20px;
}

/* Track */
.wrapper_table::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

/* Handle */
.wrapper_table::-webkit-scrollbar-thumb {
    background-color: var(--kt-scrollbar-color);
    border-radius: 20px;
}

/* Handle on hover */
.wrapper_table::-webkit-scrollbar-thumb:hover {
    background-color: var(--kt-scrollbar-hover-color);
}

.table {
    width: 100%;
    display: table;
    clear: both;
    max-width: none;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
}

.table > thead {
    vertical-align: bottom;
}

.table > tbody {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    transition: opacity .2s ease;
}

.table th {
    background-color: var(--bs-white);
    position: sticky;
    top: 0;
    z-index: 3;
}

.table tr {
    display: table-row;
    width: 100%;
}

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    overflow-wrap: break-word;
}

.table tr th,
.table tr td {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
    padding: 0.75rem 1.5rem 0.75rem 0;
}

.table tr .wrapper_loader,
.table tr .wrapper_loader--loading {
    display: block;
}

.table tr th {
    color: rgb(181, 181, 195);
    font: var(--table-heading-font);
    text-transform: uppercase;
    border-bottom: 1px dashed var(--kt-table-border-color);
}

.table tr td {
    color: rgb(63, 66, 84);
    font: var(--table-data-font);
    border-bottom: 1px dashed var(--kt-table-border-color);
    vertical-align: inherit;
}

.table tr:last-child td {
    border-bottom: 0;
}

.table tr td.tableData--regular {
    color: rgb(126, 130, 153);
    font-weight: 600;
}

.table tr td.tableData--strong {
    color: rgb(63, 66, 84);
    font-weight: 700;
}

.table thead tr th:first-child,
.table tbody tr td:first-child {
    text-align: left;
}

.tableDataLink {
    color: inherit;
    font: inherit;
    cursor: pointer;
    transition: color .2s ease;
}

.tableDataLink:hover {
    color: var(--kt-text-primary);
}
