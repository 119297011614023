.header {
    background-color: #fff;
    width: calc(100% - 265px);
    height: 70px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
}

.header {
    padding: 0 30px;
}

.header_container {
    width: 100%;
    display: flex;
    align-items: center;
}

.header__menu__hamburguer {
    display: grid;
    place-items: center;
    cursor: pointer;
}

.header__menu__hamburguer svg path {
    transition: fill 0.15s ease;
}

.header__menu__hamburguer:hover svg path {
    fill: var(--kt-text-primary);
}

.header__profile {
    width: fit-content;
    position: relative;
    display: grid;
    place-items: center;
    cursor: pointer;
    margin: 0 0 0 auto;
}

.header__profile img {
    border-radius: 0.475rem;
}

.header__profile__menu {
    display: none;
    width: 275px;
    position: fixed;
    inset: 0px 0px auto auto;
    background-color: var(--kt-menu-dropdown-bg-color);
    box-shadow: var(--kt-menu-dropdown-box-shadow);
    padding: 1rem 0;
    margin: 0px;
    border-radius: 0.475rem;
    transform: translate3d(-30px, 70px, 0px);
    z-index: 5;
}

.header__profile__menu--open {
    display: block;
    will-change: transform;
    animation:
        menu-sub-dropdown-animation-fade-in .3s ease 1,
        menu-sub-dropdown-animation-move-up .3s ease 1;
}

.header__profile__header {
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid var(--kt-border-color)
}

.header__profile__header_container {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 0.8rem 1.5rem;
}

.header__profile__header__text {
    display: flex;
    flex-direction: column;
}

.header__profile__header__text__name {
    max-width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.15rem;
    font-weight: 600;
}

.header__profile__header__text__email {
    max-width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--kt-text-muted);
    font-size: .95rem;
    font-weight: 500;
    transition: color .2s ease;
}

.header__profile__menulist {
    padding: 0.8rem 1.5rem;
}

.header__profile__menuitem {
    display: flex;
    align-items: center;
    padding: 0.65rem 1.25rem;
    border-radius: 0.475rem;
    cursor: pointer;
    transition: color .2s ease;
    outline: 0;
}

.header__profile__menuitem:hover {
    background-color: var(--kt-menu-link-bg-color-hover);
    color: var(--kt-menu-link-color-hover);
}

.header__profile__menuitem__text {
    font-size: 1.075rem;
    font-weight: 500;
    color: var(--kt-gray-800);
}

.header__menu {
    display: none;
}

.sidebar--small ~ .dashboard__content .header {
    width: calc(100% - 75px);
}
/* Responsive Design */
@media (max-width: 1024px) {
    .header {
        width: 100% !important;
        height: 60px;
        padding: 0 20px;
    }

    .header__menu {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .header__profile > img {
        width: 30px;
        height: 30px;
    }

    .header__profile__menu {
        transform: translate3d(-18px, 60px, 0px);
    }

    .sidebar--small ~ .dashboard__content {
        width: 100% !important;
    }
}
/* End of Responsive Design */
