.batch .view__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
}

.batch .filter__option--company-dropdown {
    display: none;
  }

/* Responsive Design */
@media (max-width: 1024px) {
    .batch .view__body {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
/* End of Responsive Design */
