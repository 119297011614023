.card {
    background-color: #fff;
    min-width: 325px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
}

.card_container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding: 20px 30px;
}

.card__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.card__header__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.card__header__title h3 {
    color: #3F4254;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 16.5px;
    font-weight: 600;
    line-height: 20px;
}

.card__header__title__children {
    display: flex;
    align-items: center;
    gap: 8px;
}

.card__header__subtitle h4 {
    color: #B5B5C3;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.5px;
}

.card__body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
