.toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toggle__arrow {
    width: 1.25rem;
    margin-right: 0.75rem;
    transition: transform .3s ease;
    backface-visibility: hidden;
    will-change: transform;
}

.toggle__arrow__icon {
    line-height: 1;
    color: var(--kt-text-muted);
}

.toggle__arrow__icon svg {
    height: 1.35rem;
    width: 1.35rem;
}

.toggle__icon {
    background-color: var(--kt-primary-light);
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px 0 0;
}

.toggle__summary {
    margin-right: 0.75rem;
}

.toggle__summary__top {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.toggle__summary__top__first {
    font-size: 1.25rem!important;
    font-weight: 600!important;
}

.toggle__summary__bottom {
    color: var(--kt-text-muted);
}

.toggle__content {
    display: none;
    opacity: 0;
    max-height: 0;
    padding: 0;
    transition: all 0.2s ease;
}

.wrapper_toggle--isCollapsed .toggle__content {
    display: block;
    z-index: 1;
    opacity: 1;
    max-height: 100%;
    padding: 1rem 0 0 2rem;
    transition: all 0.2s ease;
}

.toggle__content_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.toggle__content__left,
.toggle__content__right {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.toggle__content__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.toggle__content__item--label {
    color: var(--kt-text-gray-600);
    font-weight: 500;
}

.toggle__content__item--text {
    color: var(--kt-text-gray-800);
    font-size: 1.075rem;
    font-weight: 600;
}
