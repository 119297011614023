.batchdispersionstatedetail {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.batchdispersionstatedetail__payorders {
    display: flex;
    align-items: center;
    gap: 12px;
}

.batchdispersionstatedetail__payorders__icon {
    width: 45px;
    height: 45px;
    background-color: #ffc700;
    display: grid;
    place-items: center;
    border-radius: 6px;
}

.batchdispersionstatedetail__payorders__time {
    color: #B5B5C3;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}

.batchdispersionstatedetail__payorders__title {
    color: #3F4254;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.batchdispersionstatedetail__process {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.batchdispersionstatedetail__process__timeline {
    min-width: 270px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.batchdispersionstatedetail__process__timeline__state {
    background-color: var(--kt-primary-light);
    width: fit-content;
    color: var(--kt-primary);
    font: var(--badge-font);
    padding: 4px 6px;
    border-radius: 6px;
}

.batchdispersionstatedetail__process__chart {
    flex: 1 1 auto;
    display: grid;
    place-items: center;
}