.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 0;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}

.button--primary {
    background-color: rgb(0, 158, 247);
    color: rgb(255, 255, 255);
}

.button--primary:hover {
    color: #ffffff;
    background-color: #0095e8;
    box-shadow: none;
}

.button--secondary {
    color: var(--kt-light-inverse);
    background-color: var(--kt-primary-light);
    box-shadow: none;
}

.button--secondary:hover {
    color: var(--kt-text-primary);
    background-color: var(--kt-primary-light);
}

.button--tertiary {
    color: var(--kt-text-gray-700);
    background-color: var(--bs-body-bg);
    box-shadow: none;
}

.button--tertiary:hover {
    color: var(--kt-primary);
}

.button--red {
    background-color: var(--kt-text-danger);
    color: #ffffff;
}

.button--red:hover {
    background-color: var(--kt-danger-active);
}

.button__title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
}

.button__title p {
    font-size: 16px;
}

.button__title span {
    font-size: 12px;
    font-weight: 400;
}

.button__text {
    font: var(--button-regular-font);
}

.button--tertiary .button__text {
    font-weight: 600 !important;
}

.button--small {
    height: 35px;
    padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
    border-radius: 0.425rem;
}

.button--small .button__text {
    font: var(--button-small-font);
}

.button--regular {
    height: 45px;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    border-radius: 6px;
}

.button--large {
    height: 50px;
    padding: calc(0.775rem + 1px) calc(1.5rem + 1px);
    border-radius: 0.475rem;
}

.button--large .button__text {
    font: var(--button-large-font);
}

.button--fit {
    width: fit-content;
}

.button--disabled,
.button--disabled:hover {
    background-color: var(--bs-gray);
    opacity: var(--kt-btn-disabled-opacity);
    cursor: not-allowed;
}
