.batchupload__actions {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
}

.batchupload__status {
  background-color: var(--kt-gray-100);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0.5rem 1rem;
  border-radius: 0.475rem;
}

.batchupload__status__text {
  color: var(--kt-gray-600);
  font-size: 12px;
  font-weight: 500;
  line-height: 17.5px;
}

.batchupload__status__text--already {
  color: var(--kt-danger);
  font-weight: 400;
}

.batchupload__status__text--extensionError {
  color: var(--kt-danger);
  font-weight: 400;
}

.batchupload__status__text--error {
  color: var(--kt-danger);
  font-weight: 400;
}

.batchupload__status__closeicon {
  color: var(--kt-gray-600);
  font-size: 22.5px;
  transition: color 0.2s ease;
  cursor: pointer;
}

.batchupload__status__closeicon:hover {
  color: var(--kt-primary);
}

.batchupload__uploadfiles {
  width: 100%;
  max-height: 524px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 0;
  transition: opacity 0.2s ease;
}

/* width */
.batchupload__uploadfiles::-webkit-scrollbar {
  width: var(--kt-scrollbar-size);
  height: var(--kt-scrollbar-size);
  border-radius: 20px;
}

/* Track */
.batchupload__uploadfiles::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}

/* Handle */
.batchupload__uploadfiles::-webkit-scrollbar-thumb {
  background-color: var(--kt-scrollbar-color);
  border-radius: 20px;
}

/* Handle on hover */
.batchupload__uploadfiles::-webkit-scrollbar-thumb:hover {
  background-color: var(--kt-scrollbar-hover-color);
}

.batchupload__toast {
  color: white !important;
  font-size: 14px !important;
  width: 370px !important;
  margin-bottom: 10px !important;
}

.batchupload__toast--processing {
  background-color: #009ef7 !important;
}

.batchupload__toast--waiting {
  background-color: #f1bc00 !important;
}

.Toastify__close-button {
  color: white !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  padding-right: 8px !important;
  opacity: 1 !important;
}

.Toastify__close-button:hover {
  opacity: 0.5 !important;
}