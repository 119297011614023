.processStatusLoader {
    min-width: 26px;
    min-height: 26px;
    width: 26px;
    height: 26px;
    border: 3px solid var(--kt-text-primary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: processStatusLoaderRotation 1s linear infinite;
}

@keyframes processStatusLoaderRotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
