.uploadbutton {
    background-color: var(--kt-primary);
    color: var(--kt-primary-inverse);
    width: fit-content;
    position: relative;
    padding: 8px 17px;
    border-radius: 5.5px;
    cursor: pointer;
    transition:
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
}

.uploadbutton--disabled {
    background-color: var(--kt-btn-link-disabled-color);
    opacity: var(--kt-btn-disabled-opacity);
    cursor: none;
    pointer-events: none;
}

.uploadbutton__input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
}

.uploadbutton__input::-webkit-file-upload-button {
    cursor:pointer;
}

.uploadbutton__text {
    font: var(--button-small-font);
}

.uploadbutton:not(.uploadbutton--disabled):hover {
    background-color: var(--kt-primary-active);
}
