@import url("./assets//icons//fonticon//fonticon.css");

html {
    font-size: 13px;
}

body {
    padding: 0;
    margin: 0;
    color: var(--bs-body-color);
    font: var(--body-font);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000000;
    --bs-white: #ffffff;
    --bs-gray: #7E8299;
    --bs-gray-dark: #3F4254;
    --bs-gray-100: #f5f8fa;
    --bs-gray-200: #eff2f5;
    --bs-gray-300: #E4E6EF;
    --bs-gray-400: #B5B5C3;
    --bs-gray-500: #A1A5B7;
    --bs-gray-600: #7E8299;
    --bs-gray-700: #5E6278;
    --bs-gray-800: #3F4254;
    --bs-gray-900: #181C32;
    --bs-white: #ffffff;
    --bs-light: #f5f8fa;
    --bs-primary: #009ef7;
    --bs-secondary: #E4E6EF;
    --bs-success: #50cd89;
    --bs-info: #7239ea;
    --bs-warning: #ffc700;
    --bs-warning-light: #fff8dd;
    --bs-danger: #f1416c;
    --bs-dark: #181C32;
    --bs-white-rgb: 255, 255, 255;
    --bs-light-rgb: 245, 248, 250;
    --bs-primary-rgb: 0, 158, 247;
    --bs-secondary-rgb: 228, 230, 239;
    --bs-success-rgb: 80, 205, 137;
    --bs-info-rgb: 114, 57, 234;
    --bs-warning-rgb: 255, 199, 0;
    --bs-danger-rgb: 241, 65, 108;
    --bs-dark-rgb: 24, 28, 50;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 24, 28, 50;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: Inter,Helvetica,"sans-serif";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #181C32;
    --bs-body-bg: #ffffff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #eff2f5;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.475rem;
    --bs-border-radius-sm: 0.425rem;
    --bs-border-radius-lg: 0.625rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #009ef7;
    --bs-link-hover-color: #0095e8;
    --bs-code-color: #b93993;
    --bs-highlight-bg: #fff3cd;

    /* Fonts variables */
    --bs-font-sans-serif: "Inter", sans-serif;
    --title-font: normal normal 600 32px/38px var(--bs-font-sans-serif);
    --link-font: normal normal 500 1rem/19.5px var(--bs-font-sans-serif);
    --navitem-font: normal normal 600 14px/14px var(--bs-font-sans-serif);
    --badge-small-font: normal normal 600 11px/11px var(--bs-font-sans-serif);
    --badge-regular-font: normal normal 600 13px/13px var(--bs-font-sans-serif);
    --badge-large-font: normal normal 600 0.95rem/1 var(--bs-font-sans-serif);
    --dropdown-font: normal normal 600 12px/18px var(--bs-font-sans-serif);
    --dropdown-large-font: normal normal 500 1.15rem/1.5 var(--bs-font-sans-serif);
    --dropdown-option-font: normal normal 500 13px/15px var(--bs-font-sans-serif);
    --menu-heading-font: normal normal 600 14px/21px var(--bs-font-sans-serif);
    --datepicker-font: normal normal 500 1.15rem/1.5 var(--bs-font-sans-serif);
    --table-heading-font: normal normal 600 12.5px/18.5px var(--bs-font-sans-serif);
    --table-data-font: normal normal 600 14px/32px var(--bs-font-sans-serif);

    --body-font: normal normal var(--bs-body-font-weight) var(--bs-body-font-size)/var(--bs-body-line-height) var(--bs-font-sans-serif);
    --button-small-font: normal normal var(--bs-btn-font-weight) 0.925rem/var(--bs-btn-line-height) var(--bs-font-sans-serif);
    --button-regular-font: normal normal var(--bs-btn-font-weight) var(--bs-btn-font-size)/var(--bs-btn-line-height) var(--bs-font-sans-serif);
    --button-large-font: normal normal var(--bs-btn-font-weight) 1.15rem/1.5 var(--bs-font-sans-serif);
    --inputfield-font: normal normal 500 1.15rem/1.5 var(--bs-font-sans-serif);

    /* Gradientes variables */
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));

    /* Variables del Tema */
    /** Tema kt **/
    --kt-xs: 0;
    --kt-sm: 576px;
    --kt-md: 768px;
    --kt-lg: 992px;
    --kt-xl: 1200px;
    --kt-xxl: 1400px;
    --kt-white: #ffffff;
    --kt-white-bg-rgb: 255,255,255;
    --kt-black: #000000;
    --kt-black-bg-rgb: 0,0,0;
    --kt-text-muted: #A1A5B7;
    --kt-gray-100: #f5f8fa;
    --kt-gray-200: #eff2f5;
    --kt-gray-300: #E4E6EF;
    --kt-gray-400: #B5B5C3;
    --kt-gray-500: #A1A5B7;
    --kt-gray-600: #7E8299;
    --kt-gray-700: #5E6278;
    --kt-gray-800: #3F4254;
    --kt-gray-900: #181C32;
    --kt-gray-100-rgb: 245,248,250;
    --kt-gray-200-rgb: 239,242,245;
    --kt-gray-300-rgb: 228,230,239;
    --kt-gray-400-rgb: 181,181,195;
    --kt-gray-500-rgb: 161,165,183;
    --kt-gray-600-rgb: 126,130,153;
    --kt-gray-700-rgb: 94,98,120;
    --kt-gray-800-rgb: 63,66,84;
    --kt-gray-900-rgb: 24,28,50;
    --kt-white: #ffffff;
    --kt-light: #f5f8fa;
    --kt-primary: #009ef7;
    --kt-secondary: #E4E6EF;
    --kt-success: #50cd89;
    --kt-info: #7239ea;
    --kt-warning: #ffc700;
    --kt-danger: #f1416c;
    --kt-dark: #181C32;
    --kt-primary-active: #0095e8;
    --kt-secondary-active: #B5B5C3;
    --kt-light-active: #eff2f5;
    --kt-success-active: #47be7d;
    --kt-info-active: #5014d0;
    --kt-warning-active: #f1bc00;
    --kt-danger-active: #d9214e;
    --kt-dark-active: #131628;
    --kt-primary-light: #f1faff;
    --kt-secondary-light: #f5f8fa;
    --kt-success-light: #e8fff3;
    --kt-info-light: #f8f5ff;
    --kt-warning-light: #fff8dd;
    --kt-danger-light: #fff5f8;
    --kt-dark-light: #eff2f5;
    --kt-primary-inverse: #ffffff;
    --kt-secondary-inverse: #3F4254;
    --kt-light-inverse: #7E8299;
    --kt-success-inverse: #ffffff;
    --kt-info-inverse: #ffffff;
    --kt-warning-inverse: #ffffff;
    --kt-danger-inverse: #ffffff;
    --kt-dark-inverse: #ffffff;
    --kt-white-rgb: 255,255,255;
    --kt-light-rgb: 245,248,250;
    --kt-primary-rgb: 0,158,247;
    --kt-secondary-rgb: 228,230,239;
    --kt-success-rgb: 80,205,137;
    --kt-info-rgb: 114,57,234;
    --kt-warning-rgb: 255,199,0;
    --kt-danger-rgb: 241,65,108;
    --kt-dark-rgb: 24,28,50;
    --kt-text-white: #ffffff;
    --kt-text-primary: #009ef7;
    --kt-text-secondary: #E4E6EF;
    --kt-text-light: #f5f8fa;
    --kt-text-success: #50cd89;
    --kt-text-info: #7239ea;
    --kt-text-warning: #ffc700;
    --kt-text-danger: #f1416c;
    --kt-text-dark: #181C32;
    --kt-text-muted: #A1A5B7;
    --kt-text-gray-100: #f5f8fa;
    --kt-text-gray-200: #eff2f5;
    --kt-text-gray-300: #E4E6EF;
    --kt-text-gray-400: #B5B5C3;
    --kt-text-gray-500: #A1A5B7;
    --kt-text-gray-600: #7E8299;
    --kt-text-gray-700: #5E6278;
    --kt-text-gray-800: #3F4254;
    --kt-text-gray-900: #181C32;
    --kt-body-bg: #ffffff;
    --kt-body-bg-rgb: 255,255,255;
    --kt-body-color: #181C32;
    --kt-link-color: #009ef7;
    --kt-link-hover-color: #0095e8;
    --kt-border-color: #eff2f5;
    --kt-border-dashed-color: #E4E6EF;
    --kt-component-active-color: #ffffff;
    --kt-component-active-bg: #009ef7;
    --kt-component-hover-color: #009ef7;
    --kt-component-hover-bg: #F4F6FA;
    --kt-component-checked-color: #ffffff;
    --kt-component-checked-bg: #009ef7;
    --kt-box-shadow-xs: 0 0.1rem 0.75rem 0.25rem rgba(0, 0, 0, 0.05);
    --kt-box-shadow-sm: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    --kt-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --kt-box-shadow-lg: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1);
    --kt-headings-color: #181C32;
    --kt-table-color: #181C32;
    --kt-table-bg: transparent;
    --kt-table-striped-color: #181C32;
    --kt-table-striped-bg: rgba(245, 248, 250, 0.75);
    --kt-table-accent-bg: transparent;
    --kt-table-active-color: #181C32;
    --kt-table-active-bg: #f5f8fa;
    --kt-table-hover-colorr: #181C32;
    --kt-table-hover-bg: #f5f8fa;
    --kt-table-border-color: #eff2f5;
    --kt-table-caption-color: #A1A5B7;
    --kt-table-loading-message-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-table-loading-message-bg: #ffffff;
    --kt-table-loading-message-color: #5E6278;
    --kt-input-btn-focus-color: rgba(0, 158, 247, 0.25);
    --kt-input-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
    --kt-input-btn-focus-color-opacity: 0.25;
    --kt-input-color: #5E6278;
    --kt-input-placeholder-color: #A1A5B7;
    --kt-input-plaintext-color: #5E6278;
    --kt-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
    --kt-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
    --kt-btn-active-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --kt-btn-disabled-opacity: 0.65;
    --kt-btn-link-color: #009ef7;
    --kt-btn-link-hover-color: #0095e8;
    --kt-btn-link-disabled-color: #7E8299;
    --kt-form-label-color: #3F4254;
    --kt-form-text-color: #A1A5B7;
    --kt-input-bg: #ffffff;
    --kt-input-disabled-bg: #eff2f5;
    --kt-input-disabled-color: #A1A5B7;
    --kt-input-disabled-border-color: #E4E6EF;
    --kt-input-color: #5E6278;
    --kt-input-border-color: #E4E6EF;
    --kt-input-focus-bg: #ffffff;
    --kt-input-focus-border-color: #B5B5C3;
    --kt-input-focus-color: #5E6278;
    --kt-input-solid-bg: #f5f8fa;
    --kt-input-solid-bg-focus: #eef3f7;
    --kt-input-solid-placeholder-color: #A1A5B7;
    --kt-input-solid-color: #5E6278;
    --kt-form-check-label-color: #A1A5B7;
    --kt-form-check-label-color-checked: #5E6278;
    --kt-form-check-input-active-filter: brightness(90%);
    --kt-form-check-input-bg: transparent;
    --kt-form-check-input-bg-solid: #eff2f5;
    --kt-form-check-input-border: 1px solid #E4E6EF;
    --kt-form-check-input-focus-border: #B5B5C3;
    --kt-form-check-input-focus-box-shadow: none;
    --kt-form-check-input-checked-color: #ffffff;
    --kt-form-check-input-checked-bg-color: #009ef7;
    --kt-form-check-input-checked-bg-color-solid: #009ef7;
    --kt-form-check-input-checked-border-color: #009ef7;
    --kt-form-check-input-checked-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23ffffff'/%3e%3c/svg%3e);
    --kt-form-check-radio-checked-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e);
    --kt-form-check-input-indeterminate-color: #ffffff;
    --kt-form-check-input-indeterminate-bg-color: #009ef7;
    --kt-form-check-input-indeterminate-border-color: #009ef7;
    --kt-form-check-input-indeterminate-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e);
    --kt-form-check-input-disabled-opacity: 0.5;
    --kt-form-check-label-disabled-opacity: 0.5;
    --kt-form-check-btn-check-disabled-opacity: 0.65;
    --kt-form-switch-color: rgba(0, 0, 0, 0.25);
    --kt-form-switch-color-solid: #ffffff;
    --kt-form-switch-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e);
    --kt-form-switch-bg-image-solid: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e);
    --kt-form-switch-focus-color: #B5B5C3;
    --kt-form-switch-focus-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23B5B5C3'/%3e%3c/svg%3e);
    --kt-form-switch-checked-color: #ffffff;
    --kt-form-switch-checked-bg-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e);
    --kt-input-group-addon-color: #5E6278;
    --kt-input-group-addon-bg: #f5f8fa;
    --kt-input-group-addon-border-color: #E4E6EF;
    --kt-form-select-color: #5E6278;
    --kt-form-select-bg: #ffffff;
    --kt-form-select-disabled-color: #A1A5B7;
    --kt-form-select-disabled-bg: #eff2f5;
    --kt-form-select-disabled-border-color: #E4E6EF;
    --kt-form-select-indicator-color: #7E8299;
    --kt-form-select-indicator: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e);
    --kt-form-select-border-color: #E4E6EF;
    --kt-form-select-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --kt-form-select-focus-border-color: #B5B5C3;
    --kt-form-select-focus-box-shadow: 0 0 0 0.25rem rgba(0, 158, 247, 0.25);
    --kt-form-file-button-color: #5E6278;
    --kt-form-file-button-bg: #f5f8fa;
    --kt-form-file-button-hover-bg: shade-color(#f5f8fa, 5%);
    --kt-nav-tabs-border-color: #eff2f5;
    --kt-nav-tabs-link-hover-border-color: #eff2f5 #eff2f5 #eff2f5;
    --kt-nav-tabs-link-active-color: #5E6278;
    --kt-nav-tabs-link-active-bg: #ffffff;
    --kt-nav-tabs-link-active-border-color: #E4E6EF #E4E6EF #ffffff;
    --kt-nav-pills-link-active-color: #ffffff;
    --kt-nav-pills-link-active-bg: #009ef7;
    --kt-dropdown-color: #181C32;
    --kt-dropdown-bg: #ffffff;
    --kt-dropdown-divider-bg: #f5f8fa;
    --kt-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-dropdown-link-color: #181C32;
    --kt-dropdown-link-hover-color: shade-color(#181C32, 10%);
    --kt-dropdown-link-hover-bg: #eff2f5;
    --kt-dropdown-link-active-color: #ffffff;
    --kt-dropdown-link-active-bg: #009ef7;
    --kt-dropdown-link-disabled-color: #A1A5B7;
    --kt-dropdown-header-color: #7E8299;
    --kt-pagination-item-bg: #ffffff;
    --kt-pagination-color: #5E6278;
    --kt-pagination-bg: transparent;
    --kt-pagination-border-color: transparent;
    --kt-pagination-focus-color: #009ef7;
    --kt-pagination-focus-bg: #F4F6FA;
    --kt-pagination-focus-box-shadow: none;
    --kt-pagination-focus-outline: 0;
    --kt-pagination-hover-color: #009ef7;
    --kt-pagination-hover-bg: #F4F6FA;
    --kt-pagination-hover-border-color: transparent;
    --kt-pagination-active-color: #ffffff;
    --kt-pagination-active-bg: #009ef7;
    --kt-pagination-active-border-color: transparent;
    --kt-pagination-disabled-color: #B5B5C3;
    --kt-pagination-disabled-bg: transparent;
    --kt-card-bg: #ffffff;
    --kt-card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    --kt-card-border-color: #eff2f5;
    --kt-card-border-dashed-color: #E4E6EF;
    --kt-card-cap-bg: transparent;
    --kt-accordion-color: #181C32;
    --kt-accordion-bg: #ffffff;
    --kt-accordion-border-color: #eff2f5;
    --kt-accordion-button-bg: #ffffff;
    --kt-accordion-button-color: #181C32;
    --kt-accordion-button-active-bg: #f5f8fa;
    --kt-accordion-button-active-color: #009ef7;
    --kt-accordion-button-focus-border-color: #eff2f5;
    --kt-accordion-button-focus-box-shadow: none;
    --kt-accordion-icon-color: #181C32;
    --kt-accordion-icon-active-color: #009ef7;
    --kt-accordion-button-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23181C32'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    --kt-accordion-button-active-icon: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23009ef7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e);
    --kt-tooltip-color: #3F4254;
    --kt-tooltip-bg: #ffffff;
    --kt-tooltip-opacity: 1;
    --kt-tooltip-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-popover-bg: #ffffff;
    --kt-popover-border-color: #ffffff;
    --kt-popover-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-popover-header-bg: #ffffff;
    --kt-popover-header-color: #3F4254;
    --kt-popover-header-border-color: #eff2f5;
    --kt-popover-body-color: #3F4254;
    --kt-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-dropdown-bg: #ffffff;
    --kt-toast-background-color: rgba(255, 255, 255, 0.85);
    --kt-toast-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --kt-toast-header-color: #7E8299;
    --kt-toast-header-background-color: rgba(255, 255, 255, 0.85);
    --kt-toast-header-border-color: rgba(0, 0, 0, 0.05);
    --kt-badge-color: #ffffff;
    --kt-modal-bg: #ffffff;
    --kt-modal-border-color: var(--bs-border-color-translucent);
    --kt-modal-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
    --kt-modal-content-bg: #ffffff;
    --kt-modal-content-border-color: var(--bs-border-color-translucent);
    --kt-modal-content-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
    --kt-modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    --kt-modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    --kt-modal-header-border-color: #eff2f5;
    --kt-modal-footer-border-color: #eff2f5;
    --kt-modal-backdrop-bg: #000000;
    --kt-modal-backdrop-opacity: 0.3;
    --kt-progress-bg: #f5f8fa;
    --kt-progress-box-shadow: none;
    --kt-list-group-color: #181C32;
    --kt-list-group-bg: #ffffff;
    --kt-list-group-border-color: rgba(0, 0, 0, 0.125);
    --kt-list-group-hover-bg: #f5f8fa;
    --kt-list-group-active-color: #ffffff;
    --kt-list-group-active-bg: #009ef7;
    --kt-list-group-active-border-colorg: #009ef7;
    --kt-list-group-disabled-color: #7E8299;
    --kt-list-group-disabled-bg: #ffffff;
    --kt-list-group-action-colorg: #5E6278;
    --kt-list-group-action-hover-color: #5E6278;
    --kt-list-group-action-active-color: #181C32;
    --kt-list-group-action-active-bg: #eff2f5;
    --kt-thumbnail-bg: #ffffff;
    --kt-thumbnail-border-color: #eff2f5;
    --kt-thumbnail-box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
    --kt-figure-caption-color: #7E8299;
    --kt-breadcrumb-divider-color: #7E8299;
    --kt-breadcrumb-active-color: #009ef7;
    --kt-carousel-custom-indicator-default-bg-color: #eff2f5;
    --kt-carousel-custom-indicator-active-bg-color: #B5B5C3;
    --kt-carousel-custom-bullet-indicator-default-bg-color: #B5B5C3;
    --kt-carousel-custom-bullet-indicator-active-bg-color: #7E8299;
    --kt-code-bg: #F1F3F8;
    --kt-code-box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.08);
    --kt-code-color: #b93993;
    --kt-btn-close-color: #000000;
    --kt-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e);
    --kt-offcanvas-border-color: var(--bs-border-color-translucent);
    --kt-offcanvas-bg-color: #ffffff;
    --kt-offcanvas-box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    --kt-offcanvas-backdrop-bg: #000000;
    --kt-offcanvas-backdrop-opacity: 0.3;
    --kt-symbol-label-color: #3F4254;
    --kt-symbol-label-bg: #f5f8fa;
    --kt-symbol-border-color: rgba(255, 255, 255, 0.5);
    --kt-bullet-bg-color: #B5B5C3;
    --kt-scrolltop-opacity: 0;
    --kt-scrolltop-opacity-on: 0.3;
    --kt-scrolltop-opacity-hover: 1;
    --kt-scrolltop-box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
    --kt-scrolltop-bg-color: #009ef7;
    --kt-scrolltop-bg-color-hover: #009ef7;
    --kt-scrolltop-icon-color: #ffffff;
    --kt-scrolltop-icon-color-hover: #ffffff;
    --kt-drawer-box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    --kt-drawer-bg-color: #ffffff;
    --kt-drawer-overlay-bg-color: rgba(0, 0, 0, 0.2);
    --kt-menu-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-menu-dropdown-bg-color: #ffffff;
    --kt-menu-heading-color: #A1A5B7;
    --kt-menu-link-color-hover: #009ef7;
    --kt-menu-link-color-show: #009ef7;
    --kt-menu-link-color-here: #009ef7;
    --kt-menu-link-color-active: #009ef7;
    --kt-menu-link-bg-color-hover: #F4F6FA;
    --kt-menu-link-bg-color-show: #F4F6FA;
    --kt-menu-link-bg-color-here: #F4F6FA;
    --kt-menu-link-bg-color-active: #F4F6FA;
    --kt-feedback-popup-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-feedback-popup-background-color: #ffffff;
    --kt-scrollbar-color: #eff2f5;
    --kt-scrollbar-hover-color: #e9edf1;
    --kt-scrollbar-size: 5px;
    --kt-scrollbar-overlay-size: 19px;
    --kt-scrollbar-overlay-space: 7px;
    --kt-overlay-bg: rgba(0, 0, 0, 0.05);
    --kt-blockui-overlay-bg: rgba(0, 0, 0, 0.05);
    --kt-rating-color-default: #B5B5C3;
    --kt-rating-color-active: #FFAD0F;
    --kt-ribbon-label-box-shadow: 0px -1px 5px 0px rgba(24, 28, 50, 0.1);
    --kt-ribbon-label-bg: #009ef7;
    --kt-ribbon-label-border-color: #005d91;
    --kt-ribbon-clip-bg: #181C32;
    --kt-engage-btn-bg: #ffffff;
    --kt-engage-btn-color: #7E8299;
    --kt-engage-btn-color-active: #3F4254;
    --kt-app-bg-color: #f5f8fa;
    --kt-app-blank-bg-color: #ffffff;
    --kt-app-header-base-bg-color: #ffffff;
    --kt-app-header-base-bg-color-mobile: #ffffff;
    --kt-app-header-base-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    --kt-app-header-base-box-shadow-mobile: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    --kt-app-header-base-menu-link-bg-color-active: #F4F6FA;
    --kt-app-header-light-separator-color: #E4E6EF;
    --kt-app-sidebar-base-toggle-btn-box-shadow: 0px 0px 10px rgba(113, 121, 136, 0.1);
    --kt-app-sidebar-base-toggle-btn-bg-color: #ffffff;
    --kt-app-sidebar-light-bg-color: #ffffff;
    --kt-app-sidebar-light-box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
    --kt-app-sidebar-light-separator-color: #E4E6EF;
    --kt-app-sidebar-light-scrollbar-color: #eff2f5;
    --kt-app-sidebar-light-scrollbar-color-hover: #eff2f5;
    --kt-app-sidebar-light-menu-heading-color: #B5B5C3;
    --kt-app-sidebar-light-menu-link-bg-color-active: #F4F6FA;
    --kt-app-sidebar-light-header-menu-link-bg-color-active: #EAEEF2;
    --kt-app-toolbar-base-bg-color: #ffffff;
    --kt-app-toolbar-base-bg-color-mobile: #ffffff;
    --kt-app-toolbar-base-box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    --kt-app-toolbar-base-box-shadow-mobile: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    --kt-app-toolbar-base-border-top: 1px solid #eff2f5;
    --kt-app-toolbar-base-border-top-mobile: 1px solid #eff2f5;
    --kt-app-footer-bg-color: #ffffff;
    --kt-app-footer-bg-color-mobile: #ffffff;
    --kt-table-color: #181C32;
    --kt-table-bg: transparent;
    --kt-table-striped-color: #181C32;
    --kt-table-striped-bg: rgba(245, 248, 250, 0.75);
    --kt-table-accent-bg: transparent;
    --kt-table-active-color: #181C32;
    --kt-table-active-bg: #f5f8fa;
    --kt-table-hover-colorr: #181C32;
    --kt-table-hover-bg: #f5f8fa;
    --kt-table-border-color: #eff2f5;
    --kt-table-caption-color: #A1A5B7;
    --kt-table-loading-message-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --kt-table-loading-message-bg: #ffffff;
    --kt-table-loading-message-color: #5E6278;

    /** Tema bs **/
    --bs-btn-font-size: 1.1rem;
    --bs-btn-font-weight: 500;
    --bs-btn-line-height: 1.5;
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: #eff2f5;
    --bs-card-border-radius: 0.625rem;
    --bs-card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    --bs-card-inner-border-radius: calc(0.625rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: transparent;
    --bs-card-bg: #ffffff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    --bs-badge-padding-x: 0.5rem;
    --bs-badge-padding-y: 0.325rem;
    --bs-badge-font-size: 0.85rem;
    --bs-badge-font-weight: 600;
    --bs-badge-color: #ffffff;
    --bs-badge-border-radius: 0.425rem;
    --bs-dropdown-bg: var(--bs-body-bg);
    --bs-dropdown-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    --bs-component-active-color: #ffffff;
    --bs-component-active-bg: #009ef7;
    --bs-component-hover-color: #009ef7;
    --bs-component-hover-bg: #F9F9F9;
    --bs-component-checked-color: #ffffff;
    --bs-component-checked-bg: #009ef7;
    --bs-table-color: var(--kt-table-color);
    --bs-table-bg: var(--kt-table-bg);
    --bs-table-border-color: var(--kt-table-border-color);
    --bs-table-accent-bg: var(--kt-table-accent-bg);
    --bs-table-striped-color: var(--kt-table-striped-color);
    --bs-table-striped-bg: var(--kt-table-striped-bg);
    --bs-table-active-color: var(--kt-table-active-color);
    --bs-table-active-bg: var(--kt-table-active-bg);
    --bs-table-hover-color: var(--kt-table-hover-color);
    --bs-table-hover-bg: var(--kt-table-hover-bg);
}

* {
    font: var(--body-font);
    font-display: swap;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

input:focus, input:focus-visible {
    outline: 0;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

font {
    font: inherit;
}

/* Responsive Design */
@media (max-width: 1024px) {
    html {
        font-size: 12px;
    }
}
/* End of Responsive Design */
