/** Page Styles **/
.page {
    height: 100vh;
    background-color: var(--kt-app-blank-bg-color);
}

.page_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
}

/* Page Main */
.page__main {
    width: 50%;
    height: 100%;
    display: grid;
    place-items: center;
    padding: 32.5px;
}

.page__main_container {
    width: 100%;
    height: 100%;
    max-width: 450px;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
}

.page__main__header {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
}

.page__main__header_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page__main__header_return {
    width: calc(1.5em + 1.55rem + 2px);
    height: calc(1.5em + 1.55rem + 2px);
    background-color: var(--kt-light);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.page__main__header_return svg path {
    fill: #181C32;
}

.page__main__header__text {
    color: var(--kt-text-gray-400);
    display: flex;
    align-items: center;
    margin: 0 0 0 auto;
}

.page__main__header__text span {
    font-size: 1.15rem;
    font-weight: 600;
    line-height: 22.5px;
    margin: 0 0.75rem 0 0;
}

.page__main__header__text a {
    color: #009ef7;
    font-size: 1.15rem;
    font-weight: 600;
    line-height: 22.5px;
    transition: color .2s ease;
}

.page__main__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    padding: 5rem 0;
}

.page__main__form__header {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0 0 2.5rem 0;
}

.page__main__form__title {
    color: var(--kt-text-dark);
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.2;
}

.page__main__form__description {
    color: var(--kt-text-gray-400);
    font-size: 1.075rem;
    font-weight: 500;
    line-height: var(--bs-body-line-height);
}

.page__main__form__inputfields {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
    margin: 0 0 2.5rem 0;
}

.page__main__form__link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin: 0 0 2.5rem 0;
}

.page__main__form__error {
    opacity: 1;
    height: 21px;
    color: #f1416c;
    font-size: 1.075rem;
    font-weight: 500;
    line-height: var(--bs-body-line-height);
}

.page__main__form__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 13px;
}

.page__main__form__wrapper_otpinput {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.page__main__form__otpinput {
    background-color: var(--kt-input-solid-bg);
    width: 60px !important;
    height: 60px;
    color: var(--kt-input-solid-color);
    text-align: center;
    font-size: calc(1.35rem + 1.2vw);
    font-weight: 500;
    line-height: 1.5;
    padding: 0.775rem 1rem;
    margin: 0.5rem 0.25rem;
    border: 1px solid var(--kt-input-border-color);
    border-radius: 0.475rem;
    transition: color .2s ease;
    box-shadow: none;
}

.page__main__form__otpinput::-webkit-inner-spin-button,
.page__main__form__otpinput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page__main__footer {
    width: 100%;
    height: 47.15px;
}
/* End of Page Main */

/* Page Aside */
.page__aside {
    background-image: url("./assets/images/bgob.png");
    background-size: cover;
    background-position-x: left;
    background-position-y: center;
    background-repeat: no-repeat;
    width: 50%;
    height: 100%;
}
/* End of Page Aside */

/* View styles */
.view {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.view__header {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.view__header h1 {
    color: #181C32;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.view__header h2 {
    color: #A1A5B7;
    font-family: Inter, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}
/* End of View styles */

/* Responsive Design */
@media (max-width: 1024px) {
    .page_container {
        flex-direction: column;
    }

    .page__main {
        width: 100%;
        padding: 36px;
    }

    .page__main_container {
        gap: 0;
    }

    .page__main__form__title {
        font-size: calc(1.425rem + 2.1vw);
    }

    .page__aside {
        display: none;
    }
}
/* End of Responsive Design */

/** End of Page Styles **/
