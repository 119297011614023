.modal {
    background: var(--kt-modal-bg);
    width: 380px;
    max-width: 380px;
    padding: 20px 30px;
    border: 1px solid var(--kt-modal-border-color);
    border-radius: 8px;
    box-shadow: var(--kt-modal-box-shadow);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.modal_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.modal__title {
    color: #3F4254;
    font-size: 16.5px;
    font-weight: 600;
    line-height: 20px;
}

.modal__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.5px;
}

.App .modal .button,
.App .modal .button:hover,
.App .modal .button:active,
.App .modal .button:focus {
    pointer-events: all;
    cursor: pointer;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .modal {
        width: 90%;
    }
}
/* End of Responsive Design */
